import fs from "file-saver";
import * as XLSX from "xlsx";

export function getExcel(json, fields, filename = "测试数据.xlsx") {
  json.forEach((item) => {
    for (let i in item) {
      if (fields.hasOwnProperty(i)) {
        item[fields[i]] = item[i];
      }
      delete item[i]; //删除原先的对象属性
    }
  });

  let sheetName = filename; //excel的文件名称
  let wb = XLSX.utils.book_new(); //工作簿对象包含一SheetNames数组，以及一个表对象映射表名称到表对象。XLSX.utils.book_new实用函数创建一个新的工作簿对象。
  let ws = XLSX.utils.json_to_sheet(json, { header: Object.values(fields) }); //将JS对象数组转换为工作表。
  ws["!cols"] = [
    { wch: 30 },
    { wch: 10 },
    { wch: 20 },
    { wch: 30 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
    { wch: 10 },
    { wch: 10 },
  ];
  ws["A1"].s = {
    font: {
      name: '宋体',
      sz: 10,
      color: {rgb: "ffffff"},
      bold: true,
      italic: false,
      underline: false,
      height: 20
    },
  };
  wb.SheetNames.push(sheetName);
  wb.Sheets[sheetName] = ws;
  const defaultCellStyle = {
    font: { name: "Verdana", sz: 11, color: "FF00FF88" },
    fill: { fgColor: { rgb: "FFFFAA00" } },
  }; //设置表格的样式
  let wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
    cellStyles: true,
    defaultCellStyle: defaultCellStyle,
    showGridLines: false,
  }; //写入的样式
  let wbout = XLSX.write(wb, wopts);
  let blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  fs.saveAs(blob, filename + ".xlsx");
}
const s2ab = (s) => {
  if (typeof ArrayBuffer !== "undefined") {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  } else {
    var buf = new Array(s.length);
    for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
};
/* export function getExcel({
  multiHeader = [],
  header,
  data,
  filename,
  merges = [],
  autoWidth = true,
  bookType = 'xlsx'
} = {}) {

  filename = filename || 'excel-list'
  data = [...data]
  data.unshift(header);
 
  for (let i = multiHeader.length - 1; i > -1; i--) {
    data.unshift(multiHeader[i])
  }
 
  var ws_name = "SheetJS";
  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);
 
  if (merges.length > 0) {
    if (!ws['!merges']) ws['!merges'] = [];
    merges.forEach(item => {
      ws['!merges'].push(XLSX.utils.decode_range(item))
    })
  }
 
  if (autoWidth) {
    // 设置worksheet每列的最大宽度
    const colWidth = data.map(row => row.map(val => {
      // 先判断是否为null/undefined
      if (val == null) {
        return {
          'wch': 10
        };
      }
      // 再判断是否为中文
      else if (val.toString().charCodeAt(0) > 255) {
        return {
          'wch': val.toString().length * 2
        };
      } else {
        return {
          'wch': val.toString().length
        };
      }
    }))
    // 以第一行为初始值
    let result = colWidth[0];
    for (let i = 1; i < colWidth.length; i++) {
      for (let j = 0; j < colWidth[i].length; j++) {
        if (result[j]['wch'] < colWidth[i][j]['wch']) {
          result[j]['wch'] = colWidth[i][j]['wch'];
        }
      }
    }
    ws['!cols'] = result;
  }
 
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;
 
  var wbout = XLSX.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });
  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), `${filename}.${bookType}`);
} */

// 经过评论反馈优化
/* function getCellWidth(value) {
  // 判断是否为null或undefined
  if (value == null) {
    return 10;
  } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
    // 中文的长度
    const chineseLength = value.match(/[\u4e00-\u9fa5]/g).length;
    // 其他不是中文的长度
    const otherLength = value.length - chineseLength;
    return chineseLength * 2.1 + otherLength * 1.1;
  } else {
    return value.toString().length * 1.1;
    // 另一种方案
    // value = value.toString()
    // return value.replace(/[\u0391-\uFFE5]/g, 'aa').length
   
  }
} */

/**
 * 将table、json数据导出为excel
 * @param {object} options
 * @param {[]} options.data 数据源
 * @param {"table"|"json"|"aoe"} options.dataType 数据类型
 * @param {string} options.sheetName sheet名称
 * @param {boolean} options.saveFile 是否保存为文件，如果为false则返回workBook
 * @param {string} options.fileName 文件名称
 * @param {boolean} options.fitWidth是否自适应列宽(如果dataType="json",配置此属性将无效)
 * @param {[]} options.header xlsx内部参数
 */
/* export function getExcel(
  {
    data = [],
    dataType = 'table',
    sheetName = '财务列表',
    saveFile = true,
    fileName = new Date().toString() + '.xlsx',
    fitWidth= true,
    header = []
  } = {}
) {
  try {
    if (!XLSX) throw 'exportTo: the plug-in "XLSX" is undefined.'
    if (!data || data.length === 0) throw 'exportTo: data is null or undefined.'

    let sheet = {}
    switch (dataType) {
      case 'table':
        sheet = XLSX.utils.table_to_sheet(data, { raw: true })
        break
      case 'json':
        sheet = XLSX.utils.json_to_sheet(data, { raw: true, header: header })

        if (fitWidth) {
          let colWidths = [],
            colNames = Object.keys(data[0]) // 所有列的名称数组

          // 计算每一列的所有单元格宽度
          // 先遍历行
          data.forEach((row) => {
            // 列序号
            let index = 0
            // 遍历列
            for (const key in row) {
              if (colWidths[index] == null) colWidths[index] = []

              switch (typeof row[key]) {
                case 'string':
                case 'number':
                case 'boolean':
                  colWidths[index].push(getCellWidth(row[key]))
                  break
                case 'object':
                case 'function':
                  colWidths[index].push(0)
                  break
              }
              index++
            }
          })

          sheet['!cols'] = []
          // 每一列取最大值最为列宽
          colWidths.forEach((widths, index) => {
            // 计算列头的宽度
            widths.push(getCellWidth(colNames[index]))
            // 设置最大值为列宽
            sheet['!cols'].push({ wch: Math.max(...widths) })
          })
        }
        break
      case 'aoe':
        // 未实现
        sheet = []
        break
    }

    let workBook = {
      SheetNames: [sheetName],
      Sheets: {
        [sheetName]: sheet
      }
    }

    if (saveFile) {
      XLSX.writeFile(workBook, fileName)
      return true
    } else {
      return workBook
    }
  } catch (error) {
    console.error('exportTo: ', error)
    throw error
  }
}
 */
